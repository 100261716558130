 #statistics .headercont{
    background: white;
    padding-left: 20px;
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    color:#2a2a2a;
    margin-bottom: 20px;
    /* box-shadow: 0 0 2px 0px  #2a2a2a; */
}

#statistics .dashboard{
    height: 140px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
}

#statistics .tongji{
    width: 100%;
    min-height: 500px;
    background: white;
    margin-top: 20px;
    padding-top: 10px;
}


#statistics .areastatistics{
    height: 340px;
    width: 100%;
    padding-top: 10px;
    background: white;
    margin-bottom: 20px;
  
}

#statistics .scenetxt{
    font-size: 16px;
    font-weight: bold;
    color:#fd7a12;
}

#statistics .sceneleft{
    font-size: 16px;
    color:#999;
    margin-left: 20px;
}



#statistics .dashtext{
    color:#fe8114;
}

#statistics .dashtext1{
    color:#46a7f2;
}




#statistics .dashtext2{
    color:#ffad1e;
}

#statistics .dashtext3{
    color:#63cd5d;
}


#statistics .dashtxt{
    font-size: 40px;
    font-weight: bold;
    margin-right: 8px;
}

#statistics .dashbottxt{
    color:#555;
    font-size: 16px;
}



#statistics .righthead{
    border-left:4px solid  #fd7a12;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    color:#2a2a2a;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.alarmtable  .ant-table-thead > tr > th{
    text-align: center;
    background: white ;
    padding: 16px 5px;
}

.alarmtable .ant-table-container table > thead > tr:first-child th:last-child{
    text-align: center;
}


.alarmtable .ant-table-tbody > tr > td:first-child{
    text-align: center;
}

.alarmtable .ant-table-tbody > tr > td:last-child{
    text-align: center;
}


#statistics  .firstcircle{
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #f33920;
    color:white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#statistics  .twocircle{
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #fd7a12;
    color:white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#statistics  .threecircle{
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #fdb212;
    color:white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.circlestyle{
    text-align: center;
    font-size: 24px;
}


.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content{
    overflow-x: visible;
}

#menulist{
    padding-left: 20px;
}

#menulist  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{

    background: url("http://disimg.terabits.cn/menuback.png") no-repeat;

  }
  


  #menulist .ant-menu-item-selected a span{
    color:#fc700e;
    font-size: 16px;
  }

  .tdfirst{
      display: inline-block;
      width: 10%;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
  }

  .tdtwo{
    display: inline-block;
    width: 45%;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.tdthree{
    display: inline-block;
    width: 20%;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.tdfour{
    display: inline-block;
    width: 25%;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.tablescoll{
    height: 320px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

 .tablescoll::-webkit-scrollbar {
    display:none;
}

@media screen and (max-width: 1700px) {
    #menulist  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{

        background: url("http://disimg.terabits.cn/menuback1.png") no-repeat;
    
      }
  }


@media screen and (max-width: 1525px) {
    .circlestyle{
        font-size: 20px;
    }
    #menulist  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{

        background: url("http://disimg.terabits.cn/menuback2.png") no-repeat;
    
      }
    
      #menulist .ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-submenu-title{
        padding-left: 25px !important;
      }
  }
  
  @media screen and (max-width: 1500px) {
    .circlestyle{
        font-size: 15px;
    }
    .tdfirst{
      font-size: 12px;
    }
  
    .tdtwo{
        font-size: 12px;
  }
  .tdthree{
    font-size: 12px;
  }
  
  .tdfour{
    font-size: 12px;
  }
  #statistics .dashtxt{
    font-size: 25px;
}
#statistics .ant-col-5{
    padding-left: 6px !important;
    padding-right: 6px ;
}

#statistics .ant-col-4{
    padding-left: 6px ;
    padding-right: 6px ;
}   

}

  @media screen and (max-width: 1450px) {
    .circlestyle{
        font-size: 15px;
    }

    
  }

  
  @media screen and (max-width: 1350px) {
    #menulist  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{

        background: url("http://disimg.terabits.cn/menuback3.png") no-repeat;
    
      }
    .tdfirst{
        width: 5%;
    }
  
    .tdtwo{
      width: 40%;
  }
  .tdthree{
      width: 25%;
  }
  
  .tdfour{
      width: 30%;
  }
  }