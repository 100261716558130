#mobile {
    background: #FC862E url(./back.jpg) no-repeat top;
    min-height: 100vh;
    background-size: 100%;
    padding-top: .2rem;
}

#mobile .title {
    color: white;
    font-size: .2rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
    text-shadow: rgb(16, 27, 56) 0px 3px 2px;
}

#mobile .conthead {
    background: #222B6C;
    /* opacity: 0.5; */
    width: 95%;
    height: .2rem;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: .4rem;
    position: relative;
}

#mobile .container {
    background: white;
    min-height: 4rem;
    width: 98%;
    position: absolute;
    left: 1%;
    top: 10px;
    border-radius: 10px;
    padding-left: .15rem;
    padding-bottom: .2rem;
}

#mobile .header {
    display: flex;
    align-items: center;
    margin-top: .05rem;
    margin-bottom: .05rem;
}

#mobile .line {
    background: #FD7310;
    width: 4px;
    height: .2rem;
    border-radius: 2px;
    margin-right: 7px;
}

#mobile .headertitle {
    font-size: .16rem;
    font-weight: bold;
}

#mobile .contline {
    padding-right: .15rem;
}

.imgmodel .ant-modal-body {
    padding: 0;
}

.imgmodel .ant-modal-header {
    display: none;
}

.imgmodel .ant-modal-close-x {
    display: none;
}