
#mobilesuccess{
    padding-bottom: 1.2rem;
    padding-top: 100px;
}

#mobilesuccess  .navbar img{
    margin-top: -.2rem;
    margin-bottom: .1rem;
}
#mobilesuccess  .navbar span{
    margin-top: -.15rem;
}
#mobilesuccess  .content{
    width: 90%;
    margin: 0 auto;
}

#mobilesuccess  .successtop{
    text-align: center;
    margin-top: 25px;
    font-size: .4rem;
}

#mobilesuccess  .successmid{
    text-align: center;
    margin-top: 25px;
    font-size: .4rem;
}


#mobilesuccess .footer{
    width: 100%;
    margin:0 auto;
    font-size: .1rem;
    color:white;
    background: #3098f0;
    position: fixed;
    bottom: 0;
    z-index: 99;
}
#mobilesuccess .foot{
display: flex;
height: .35rem;
justify-content: center;
align-items: center;
width: 100%;
}
#mobilesuccess .foot img{
    width: .2rem;
    margin-left: .05rem;
    margin-right: .05rem;
}
