#signbody {
  /* width: 100%;
  background: url("https://zjiva.terabits.cn/var/file/closeback.png") no-repeat;
  height: 100vh;
  background-size: 100% 100%; */
}

.dashimg {
  width: 100%;
  height: 100vh;
}