#mobiles {
    background: #303F9C url(./back.jpg) no-repeat top;
    min-height: 110vh;
    background-size: 100%;
    padding-top: .2rem;
}

#mobiles .title {
    color: white;
    font-size: .2rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
    text-shadow: rgb(16, 27, 56) 0px 3px 2px;
}

#mobiles .conthead {
    background: #222B6C;
    /* opacity: 0.5; */
    width: 95%;
    height: .2rem;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: .4rem;
    position: relative;
}

#mobiles .container {
    background: white;
    min-height: 4rem;
    width: 98%;
    position: absolute;
    left: 1%;
    top: 10px;
    border-radius: 10px;
    padding-left: .15rem;
    padding-right: .15rem;
    padding-bottom: .2rem;
}

#mobiles .header {
    display: flex;
    align-items: center;
    margin-top: .05rem;
    margin-bottom: .05rem;
}

#mobiles .line {
    background: #236DEF;
    width: 4px;
    height: .2rem;
    border-radius: 2px;
    margin-right: 7px;
}

#mobiles .headertitle {
    font-size: .16rem;
    font-weight: bold;
}

#mobiles .contline {
    font-size: .12rem;
    margin-bottom: .03rem;
}

.imgmodel .ant-modal-body {
    padding: 0;
}

.imgmodel .ant-modal-header {
    display: none;
}

.imgmodel .ant-modal-close-x {
    display: none;
}

#mobiles .headerflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#mobiles .headeright {
    font-size: .12rem;
    font-weight: normal;
}


#mobiles .ant-table-thead>tr>th,
#mobiles .ant-table-tbody>tr>td {
    padding: 5px;
}

#mobiles .ant-table-tbody>tr>td {
    padding: 5px !important;
}

#mobiles .dashedline {
    border-bottom: 1px dashed #B0B0B0;
    margin-top: .1rem;
    margin-bottom: .1rem;
}

#mobiles .contlines {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .12rem;
    border: 1px dashed #B0B0B0;
    border-radius: 10px;
    width: 50%;
    margin: 0 auto;
    margin-top: .1rem;
}

#mobiles .textinput {
    border: 1px solid #DEDEDE;
    width: 100%;
    margin-top: .05rem;
}

.submitbtn {
    background: #236CF5;
    border-radius: 10px;
    width: 80px;
    height: 30px;
    margin: 0 auto;
    font-size: .14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: .2rem;
}