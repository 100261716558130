#nodeManage {
    background: white;
    min-height: 500px;
}

.gutter-box-nodemanage {
    text-align:left;
}

.ant-input-disabled{
    color: black;
    text-align: center;
}


#NodeManage th{
    text-align: center;
}

#NodeManage td{
    text-align: center;
}


.ant-input-group{
    margin: 0;
}

.ant-card-body{
    padding: 20px;
}

.ant-table-tbody > tr > td{
    font-size: 14px;
    color:#555
}

.mapcontainer{
    width: 545px;
    height: 400px;
}

 #mapContainer{
    width: 545px;
    height: 400px;
}


.clearfix::after{
    visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}


.personinfo{
    display: flex;
    justify-content: space-between;
    line-height: 35px;
}