#boardvideo {
  background: none;
  height: 87vh;
  color: white;
}

#boardvideo .ant-row {
  height: 85vh;
}

#boardvideo .ant-row .ant-col-6 {
  /* margin-top: 20px; */
  padding: 20px;
  padding-top: 0;
}

#boardvideo .ant-tree {
  padding: 20px;
  height: 80vh;
  background: white;
  color: #333333;
  font-size: 15px;
  font-weight: 600;
}

#boardvideo .ant-tree-list {
  height: 75vh;
}

#boardvideo .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #dddcdc;
}

.downloadboard {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
}


#boardvideo  .ant-tabs-nav{
  background: white;
  margin-right: 20px;
}