.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body{
  background: #f2f2f2;
}

.headermain{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-layout-header{
  width: 100%;
  background: white;
  height: 70px;
}

.headerleft{
  font-size: 28px;
  color:#333;
  display: flex;
  align-items: center;
}

.headerright{
  text-align: right;
  display: flex;
  align-content: center;
  height: 70px;
}

.bodyleft{
  margin-left: 30px;
  margin-top: 20px;
  min-height: 85vh;
  margin-right: 20px;
  background: white;
}

.ant-layout-sider{
  min-height: 80vh;
  background: white;
  width: 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  height: 55px;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
  background: white;
  color:#555555;
}


.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-submenu-title{
  height: 55px;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 55px !important;
}


.ant-menu-item a span{
  color:#555555;
}
.ant-menu-item-selected a span{
  color:white
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: url("https://mainimg.terabits.cn/smokeback.png") no-repeat;
  width: 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  padding-left: 55px !important; 
}


.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border:1px solid #fe8616;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border:1px solid #fe8616;
  box-shadow: none;
}

.ant-btn-primary:hover, .ant-btn-primary:focus{
  background:#fe8616;
  border:1px solid #fe8616;
  opacity: 0.8;
}


.ant-picker:hover, .ant-picker-focused{
  border:1px solid #fe8616;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background:#fe8616;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border:1px solid #fe8616;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue{
  color:white;
  border:1px solid #fe8616;
  background:#fe8616;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover{
  background:#fbeadd;
}

@media screen and (max-width: 1525px) {

  .ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-submenu-title{
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 45px !important;
  }
  
  

  .bodyleft{
    min-height: 80vh;
  }

  .ant-layout-sider{
    min-height: 80vh;
    background: white;
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    height: 45px;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: url("https://mainimg.terabits.cn/smokeback2.png") no-repeat;
    width: 210px !important;
    max-width: 210px !important;
    min-width: 210px !important;
    padding-left: 45px !important; 
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding: 12px 5px;
  }
}


@media screen and (max-width: 1450px) {
  .ant-table-tbody > tr > td{
    font-size: 12px !important;
  }
}