#nodeManage {
    background: white;
    min-height: 500px;
}

.gutter-box-nodemanage {
    text-align: left;
}

.ant-input-disabled {
    color: black;
    text-align: center;
}

#device .contentmain {
    background: white;
    padding: 20px;
}

.treelist {
    width: 360px;
    border-right: 1px solid #bebebe;
    min-height: 400px;
    padding-right: 50px;
}

.chouti .ant-table-tbody>tr>td:first-child {
    text-align: center;
}


.chouti .ant-table-container table>thead>tr:first-child th:last-child {
    text-align: center;
}

.chouti .ant-table-tbody>tr>td:last-child {
    text-align: center;
}


.drawermain {
    display: flex;
    justify-content: space-between;
}



#NodeManage th {
    text-align: center;
}

#NodeManage td {
    text-align: center;
}


.ant-input-group {
    margin: 0;
}

.ant-card-body {
    padding: 20px;
}

.ant-table-tbody>tr>td {
    font-size: 14px;
    color: #555
}

#device .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2a2a2a;
    font-size: 18px;
}

.modeltable .ant-table-thead>tr>th,
.modeltable .ant-table-tbody>tr>td {
    padding: 8px 20px;
}

.ant-modal-close-x {
    height: 43px;
    width: 43px;
    line-height: 43px;
}