#monitor .headercont{
    background: white;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#monitor .headertitle{
    font-size: 20px;
    font-weight: bold;
}
#monitor .headertitles{
    text-align: center;
}

#monitor .bodycont{
    background: white;
    margin-bottom: 20px;
    height: 200px;
    
}

#monitor .bodyheader{
    padding-left: 20px;
    border-bottom: 1px solid #dedbdb;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}


#monitor .bodyline{
    padding: 20px;
}

#monitor .bodytext{
    line-height: 30px;
}